$primary-color: #3f51b5;


/* show more and show less style */
.show-more-container  {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: .25px;
}
.show-more {
    text-decoration: none;
    color: $primary-color;
}

.show-more-expanded {
    margin-left: .25rem;
    display: inline-block;
    width: 100%;
    text-align: right;
    margin-top: 1rem;
}

.dashboard-iframe {
    width: 100%;
    height: 100%;
    min-height: 85vh;
}
